@import "../base";

.Flash {
  padding: 16px;
  background: $white-100;
  text-align: center;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  border-radius: 8px;
  @include drop-shadow-level-1;
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1001;
  min-width: 300px;
  animation: fade-in 5s linear;

  &.disappearing {
    animation-name: fade-in-out;
  }

  .icon {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
  }

  &.info {
    background: $light-blue-30;
  }

  &.error {
    background: $red-10;

    .icon {
      @include filter-red-120;
    }
  }

  &.success {
    background: $deed-green-20;

    .icon {
      @include filter-deed-green-160;
    }
  }

  &.warning {
    // background: $mustard-yellow-20;

    .icon {
      // @include filter-mustard-yellow-120;
    }
  }

  .message {
    margin: auto;
    padding: 0 16px;
  }

  .close {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      height: 16px;
      width: 16px;
      // @include filter-navy-blue-80;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 1;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 1;
  }

  98% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
