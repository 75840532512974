@import "../common/base";

.action {
  min-width: 180px;
}

.title {
  margin-bottom: 24px;
}

.error,
.success {
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.error {
  color: $red-120;
  background: $red-20;
}

.success {
  color: $deed-green-140;
  background: $deed-green-20;
}

.link {
  display: block;
  margin-top: 16px;
}

.form {
  label {
    display: block;
    margin-bottom: 4px;
  }
}
