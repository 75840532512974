@import "../base";

.Layout {
  display: grid;
  grid-template-areas:
    "header header"
    "sidebar main";
  grid-template-columns: 76px auto;
  grid-template-rows: 64px auto;
  z-index: 1;
  position: relative;
  border-radius: 16px;
  @include drop-shadow-level-1;
  min-height: calc(100vh - 104px);

  & > main {
    margin: 24px;
    background: $white-100;
    border-radius: 10px;
    padding: 24px 0;
    grid-area: main;
    overflow: auto;
  }
}

.header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
}
