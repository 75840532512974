.container {
  position: relative;
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #F6F6F6;
  border-radius: 50%;
  color: #767676;
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;
  user-select: none;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  display: none;
  border-radius: 16px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  background: #ffffff;
  right: 0;
  top: calc(100% + 4px);
  &.dropdownActive {
    display: block;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
}

.menuItemLinkWrapper {
  color: inherit !important;
}

.menuItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px 16px;
  img {
    width: 14px;
  }
  &:hover {
    color: #3dcc4a;
  }
}

.menuItemLabel {}
