@import "../base";

.input {
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: $grey-5;
  color: $grey-160;

  &::placeholder {
    color: $grey-40;
  }

  &:focus {
    outline: $deed-green-80 auto 1px;
  }

  &:disabled {
    background-color: $grey-20;
    opacity: 1;
  }
}

input[type="checkbox"] {
  width: auto;
  -webkit-appearance: checkbox;
}

table {
  width: 100%;
}

th,
td {
  height: 36px;
  text-align: left;
  font-size: 14px;
  padding-right: 16px;
  color: $grey-120;
  // &:first-child {
  //   padding-left: 24px;
  // }
}

// td {
//   border-top: 1px solid $grey-20;
// }

th {
  font-weight: 600;
  &.sortable {
    cursor: pointer;
  }
}

.select {
  position: relative;
  display: flex;
  padding-right: 32px;
  height: 40px;
  background: $grey-5 url("../../assets/vector-down-grey.png") no-repeat right
    16px center / 10px;
}

.datepicker {
  background-color: $grey-5;
}
