@import "../base";

button {
  cursor: pointer;
}

@mixin btn {
  font-family: "Open Sans", "SF Pro", sans-serif;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 10px 16px;
  color: $white-100;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: visible;

  &:visited {
    color: $white-100;
  }

  img {
    margin-right: 8px;
    height: 18px;
  }

  &:disabled {
    cursor: auto;
  }

  &.btn-sm {
    font-size: 16px;
    line-height: 38px;
  }

  &.btn-circle {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      position: static;
      height: 16px;
    }
  }
}

.btn-primary {
  @include btn;
  background: $deed-green-100;
  img {
    @include filter-white;
  }

  &:hover {
    background: $deed-green-80;
  }

  &:active {
    background: $deed-green-140;
  }

  &:disabled {
    background: $deed-green-40;
  }
}

.btn-danger {
  @include btn;
  background: #FF6A56;
  img {
    @include filter-white;
  }

  &:hover {
    background: #FF6A56;
  }

  &:active {
    background: #FF6A56;
  }

  &:disabled {
    background: #FF6A56;
  }
}

.link {
  color: $deed-green-100;
  & > img {
    @include filter-deed-green-100;
  }

  &:hover {
    color: $deed-green-80;
    & > img {
      @include filter-deed-green-80;
    }
  }

  &:active {
    color: $deed-green-140;
    & > img {
      @include filter-deed-green-140;
    }
  }

  &:disabled {
    color: $deed-green-40;

    & > img {
      @include filter-deed-green-40;
    }
  }
}

.btn-secondary {
  @include btn;
  background: transparent;
  color: $deed-green-100;
  border: 1px solid $deed-green-100;
  img {
    @include filter-deed-green-100;
  }

  &:visited {
    color: $deed-green-100;
  }

  &:hover {
    border-color: $deed-green-100;
    background: $deed-green-100;
    color: $white-100;
    img {
      @include filter-white;
    }
  }

  &:active {
    border-color: $deed-green-140;
    background: $deed-green-140;
    color: $white-100;
  }

  &:disabled {
    background: transparent;
    border-color: $deed-green-40;
    color: $deed-green-40;
    img {
      @include filter-deed-green-40;
    }
  }
}

.btn-tertiary {
  @include btn;
  background: $grey-10;
  color: $grey-100;
  img {
    @include filter-grey-100;
  }

  &:hover {
    background: $grey-20;
  }

  &:active {
    background: $grey-40;
  }

  &:disabled {
    background: $grey-5;
  }
}

.btn-black {
  @include btn;
  background: $grey-160;
  color: $white-100;
  padding: 13px 16px;
  border: 1px solid $grey-160;
  img {
    @include filter-grey-100;
  }

  &:hover {
    background: transparent;
    color: $grey-160;
    img {
      @include filter-white;
    }
  }

  &:disabled {
    background: $grey-40;
    border-color: $grey-40;
  }
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px solid $grey-20;
  border-radius: 50%;
  background: $white-100;
  img {
    width: 20px;
    height: 20px;
  }
}

.btn-neutral {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  line-height: 20px;
  padding: 6px 16px;
  background: $grey-10;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    background: $grey-20;
  }

  &:active {
    background: $grey-40;
  }
}

.btn-outlined {
  @include btn;
  border: 1px solid $deed-green-100;
  color: $deed-green-100;

  &:hover {
    background: $deed-green-100;
    color: $white-100;
  }

  &:active {
    background: $deed-green-140;
  }

  &:disabled {
    background: $deed-green-40;
  }
}

.btn-full {
  width: 100%;
}
