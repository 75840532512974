@import "../base.scss";

.error {
  outline: $red-80 auto 1px;
}

.errorMessage {
  font-size: 12px;
  line-height: 14px;
  color: $red-100;
  margin: 2px 0 4px;
  min-height: 14px;
}
