// https://loading.io/asset/544578
@keyframes ldio-x60pcbbjc7 {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5, 1.5);
  }
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
.inner div > div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #56cca2;
  animation: ldio-x60pcbbjc7 0.8474576271186441s linear infinite;
}
.inner div:nth-child(1) > div {
  left: 152px;
  top: 92px;
  animation-delay: -0.7627118644067797s;
}
.inner > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 160px 100px;
}
.inner div:nth-child(2) > div {
  left: 141px;
  top: 127px;
  animation-delay: -0.6779661016949153s;
}
.inner > div:nth-child(2) {
  transform: rotate(36deg);
  transform-origin: 149px 135px;
}
.inner div:nth-child(3) > div {
  left: 111px;
  top: 149px;
  animation-delay: -0.5932203389830509s;
}
.inner > div:nth-child(3) {
  transform: rotate(72deg);
  transform-origin: 119px 157px;
}
.inner div:nth-child(4) > div {
  left: 73px;
  top: 149px;
  animation-delay: -0.5084745762711865s;
}
.inner > div:nth-child(4) {
  transform: rotate(108deg);
  transform-origin: 81px 157px;
}
.inner div:nth-child(5) > div {
  left: 43px;
  top: 127px;
  animation-delay: -0.42372881355932207s;
}
.inner > div:nth-child(5) {
  transform: rotate(144deg);
  transform-origin: 51px 135px;
}
.inner div:nth-child(6) > div {
  left: 32px;
  top: 92px;
  animation-delay: -0.33898305084745767s;
}
.inner > div:nth-child(6) {
  transform: rotate(180deg);
  transform-origin: 40px 100px;
}
.inner div:nth-child(7) > div {
  left: 43px;
  top: 57px;
  animation-delay: -0.25423728813559326s;
}
.inner > div:nth-child(7) {
  transform: rotate(216deg);
  transform-origin: 51px 65px;
}
.inner div:nth-child(8) > div {
  left: 73px;
  top: 35px;
  animation-delay: -0.16949152542372883s;
}
.inner > div:nth-child(8) {
  transform: rotate(252deg);
  transform-origin: 81px 43px;
}
.inner div:nth-child(9) > div {
  left: 111px;
  top: 35px;
  animation-delay: -0.08474576271186442s;
}
.inner > div:nth-child(9) {
  transform: rotate(288deg);
  transform-origin: 119px 43px;
}
.inner div:nth-child(10) > div {
  left: 141px;
  top: 57px;
  animation-delay: 0s;
}
.inner > div:nth-child(10) {
  transform: rotate(324deg);
  transform-origin: 149px 65px;
}
.container {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.inner div {
  box-sizing: content-box;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
/* generated by https://loading.io/ */
