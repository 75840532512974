@mixin filter-deed-green-160 {
  filter: invert(27%) sepia(20%) saturate(1088%) hue-rotate(107deg)
    brightness(90%) contrast(89%);
}

@mixin filter-deed-green-140 {
  filter: invert(37%) sepia(68%) saturate(331%) hue-rotate(107deg)
    brightness(95%) contrast(87%);
}

@mixin filter-deed-green-100 {
  filter: invert(70%) sepia(42%) saturate(524%) hue-rotate(87deg)
    brightness(91%) contrast(88%);
}

@mixin filter-deed-green-80 {
  filter: invert(83%) sepia(24%) saturate(644%) hue-rotate(83deg)
    brightness(88%) contrast(93%);
}

@mixin filter-deed-green-40 {
  filter: invert(94%) sepia(6%) saturate(1128%) hue-rotate(101deg)
    brightness(97%) contrast(91%);
}

@mixin filter-white {
  filter: brightness(0) invert(100%);
}

@mixin filter-grey-100 {
  filter: invert(36%) sepia(7%) saturate(305%) hue-rotate(102deg)
    brightness(91%) contrast(89%);
}

@mixin filter-grey-60 {
  filter: invert(70%) sepia(9%) saturate(85%) hue-rotate(98deg) brightness(89%)
    contrast(81%);
}

@mixin filter-grey-40 {
  filter: invert(84%) sepia(6%) saturate(71%) hue-rotate(109deg) brightness(91%)
    contrast(89%);
}

@mixin filter-red-120 {
  filter: invert(35%) sepia(30%) saturate(1163%) hue-rotate(314deg)
    brightness(105%) contrast(109%);
}
