@import "../base";
@import "Layout.module.scss";

.NarrowLayout {
  max-width: 1392px;
  padding: 0 8px;
  margin: 0 auto;

  @media (min-width: $tablet) {
    padding: 0 24px;
  }
}

.wrapper {
  padding: 0 8px;
  margin: 0 auto;
  max-width: 448px;

  @media (min-width: $tablet) {
    padding: 0 24px;
  }
}

.content {
  padding: 16px;
  margin-top: 20vh;
  margin-bottom: 24px;
  background: $white-100;
  border-radius: 16px;
  @include drop-shadow-level-2;

  @media (min-width: $tablet) {
    padding: 24px;
  }

  @media (min-height: 380px) {
    margin-top: 88px;
  }
}
