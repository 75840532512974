// Drop shadows go outwards in ascending order, i.e. level 4 should be on the biggest box, containing the other boxes,
// if box nesting is higher than 4, then no drop shadow. See styleguide: https://www.figma.com/file/YBZJOZgDkIFafyJjBdamA4/Sidekick-design-system?node-id=110%3A40
@mixin drop-shadow-level-1 {
  box-shadow: 0px 1px 2px 1px rgba(7, 45, 51, 0.04);
}

@mixin drop-shadow-level-2 {
  box-shadow: 0px 1px 4px 1px rgba(7, 45, 51, 0.08);
}

@mixin drop-shadow-level-3 {
  box-shadow: 0px 2px 6px 2px rgba(7, 45, 51, 0.12);
}

@mixin drop-shadow-level-4 {
  box-shadow: 0px 4px 8px 2px rgba(0, 16, 51, 0.16);
}

@mixin drop-shadow-inset {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
    inset -2px -2px 2px rgba(0, 0, 0, 0.05);
}
