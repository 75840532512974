$deed-green-160: #225132;
$deed-green-140: #34794b;
$deed-green-120: #45a264;
$deed-green-100: #56ca7d;
$deed-green-80: #78d597;
$deed-green-60: #9adfb1;
$deed-green-40: #bbeacb;
$deed-green-20: #ddf4e5;

$deed-seagreen-160: #225241;
$deed-seagreen-140: #347a61;
$deed-seagreen-120: #45a382;
$deed-seagreen-100: #56cca2;
$deed-seagreen-80: #78d6b5;
$deed-seagreen-60: #9ae0c7;
$deed-seagreen-40: #bbebda;
$deed-seagreen-20: #ddf5ec;

$mustard-yellow-120: #cc9a4a;
$mustard-yellow-100: #ffc15d;
$mustard-yellow-20: #fff3df;

$orange-100: #ff8642;
$orange-20: #ffe7d9;

$light-blue-110: #5c95e6;
$light-blue-100: #66a6ff;
$light-blue-30: #d1e4ff;

$grey-160: #222524;
$grey-140: #333735;
$grey-120: #444a47;
$grey-100: #555c59;
$grey-80: #777d7a;
$grey-60: #999d9b;
$grey-40: #bbbebd;
$grey-20: #dddede;
$grey-10: #eeefee;
$grey-5: #f7f7f7;

$red-120: #cc413f;
$red-100: #ff514f;
$red-80: #ff7472;
$red-20: #ffdcdc;
$red-10: #ffeeed;

$white-100: #fff;

$purple-100: #7983E1;

$purple-grey-10: #E6E0E9;
$purple-grey-100: #79747E;