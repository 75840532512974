@import "reset";
@import "fonts";
@import "colors";

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  color: $grey-100;
  background-color: #F6F6F6;
}

a {
  color: $deed-green-100;
  &:visited {
    color: $deed-green-100;
  }
}

.datepicker {
  position: relative;
  img {
    position: absolute;
    left: 12px;
    top: 9px;
    z-index: 1;
  }
  .input {
    padding-left: 42px;
  }
  .react-datepicker {
    font-family: "Open Sans", sans-serif;
    border: none;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 16px;
    &__header {
      background: #ffffff;
      border-bottom: none;
    }
    &__month {
      margin: 0;
    }
    &__triangle {
      &:after, &:before {
        display: none;
      }
    }
    &__day {
      width: 36px;
      height: 34px;
      line-height: 34px;
      margin: 0;
      font-size: 15px;
      color: #2A2A2A;
      &--selected, &--keyboard-selected {
        background: #56CC7E;
        color: #ffffff;
      }
      &-name {
        width: 36px;
        height: 34px;
        line-height: 34px;
        margin: 0;
        font-size: 11px;
        font-weight: 600;
        color: #767676;
      }
      &--outside-month {
        color: #EAEAEA;
      }
    }
    &__current-month {
      font-size: 15px;
      line-height: 24px;
      font-weight: 600;
      padding-bottom: 12px;
    }
    &__navigation {
      top: 23px;
      &--next {
        right: 16px;
      }
      &--previous {
        left: 16px;
      }
    }
    &__navigation-icon {
      &::before {
        width: 10px;
        height: 10px;
        border-color: #767676;
        border-width: 2px 2px 0 0;
      }
    }
  }
}

.error-label {
  color: #FF6A56;
  font-size: 14px;
}
