@import "../../common/base";

.container {
  position: fixed;
  width: 100%;
  height: 64px;
  background: #FFFFFF;
  border-bottom: 1px solid #EAEAEA;
  left: 0;
  top: 0;
  z-index: 99;
}

.content {
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
}

.logo,
.logoLink {
  height: 32px;
}

.links {
  margin-left: auto;
  display: flex;
}

.link {
  margin-left: 24px;
  display: flex;
  align-items: center;
  color: #000000;

  &:visited {
    color: #000000;
  }

  img {
    height: 16px;
    width: 16px;
    margin-right: 4px;
    @include filter-white;
  }

  &:hover {
    color: $deed-green-40;

    img {
      @include filter-deed-green-40;
    }
  }
}

.mainInfoContent {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 64px;
  background: #E3F5E9;
}

.pageTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
